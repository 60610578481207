import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import './style.css';

const Dashboard = (props) => {
  const [cookies] = useCookies([]);
  const [preCad, setPreCad] = useState(0);
  const [contato, setContato] = useState(0);
  const token = localStorage.getItem('token');
  const loja = localStorage.getItem('store');
  const { permissions } = props;
  let title = '';

  if (cookies.store)
    title = `Bem-vindo à Área de Administração da ${cookies.store}`;

  useEffect(() => {
    const config = {
      headers: {
        store: loja,
        authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/preCadastros`, config)
      .then((res) => {
        setPreCad(res.data);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/interesses`, config)
      .then((res) => {
        setContato(res.data);
      });
  }, []);

  return (
    <div className="DashboardContent" style={{ flexWrap: 'wrap' }}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          Aqui você vai encontrar uma visão geral de como a sua imobiliária
          está!
        </CardContent>
      </Card>

      {permissions &&
        (permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_PRE_CADASTRO_LIST')) &&
        preCad > 0 && (
          <Card>
            <CardHeader title="Pré cadastros novos!" />
            <CardContent>
              Existem {preCad} Pré cadastros novos!{' '}
              <Link to="/costumer">Clique Aqui</Link> para visualizá-los
            </CardContent>
          </Card>
        )}
      {permissions &&
        (permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_INTERESSES_LIST')) &&
        contato > 0 && (
          <Card>
            <CardHeader title="Interesses novos!" />
            <CardContent>
              Existem {contato} Interesses novos!{' '}
              <Link to="/contact">Clique Aqui</Link> para visualizá-los
            </CardContent>
          </Card>
        )}
    </div>
  );
};

export default Dashboard;
