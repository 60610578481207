import cpf from 'cpf';
import createDecorator from 'final-form-focus';
import React from 'react';
import {
  List,
  Datagrid,
  EditButton,
  DateField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  ImageField,
  DateInput,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  BooleanInput,
  SelectInput,
  email,
} from 'react-admin';

import {
  mascaraCPF,
  mascaraTelefone,
  mascaraCelular,
} from '../../helpers/masks';
import CEPAuto from '../Imoveis/CEPAuto';
import TogglePreCadastro from './togglePreCadastro';

const focusOnErrors = createDecorator();

const PreCadastroTitle = ({ record }) => (
  <span>
    Pré Cadastro de {record.name} em {record.date}
  </span>
);

const PreCadastroFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_PRE_CADASTRO_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const PreCadastro = (props) => {
  const { permissions } = props;
  return (
    <List
      title="Pré Cadastro"
      bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      filters={<PreCadastroFilter />}
      {...props}
    >
      <Datagrid>
        <TogglePreCadastro label="Ativo" {...props} />
        <TextField label="Nome" source="name" />
        <TextField label="E-Mail" source="email" />
        <TextField label="Telefone" source="phone" />
        <TextField label="Celular" source="cellhpone" />
        <TextField label="CEP" source="cep" />
        <TextField label="Endereço" source="address" />
        <TextField label="Número" source="number" />
        <TextField label="Complemento" source="complement" />
        <TextField label="Cidade" source="city" />
        <TextField label="Estado" source="state" />
        <DateField
          options={{ timeZone: 'UTC' }}
          locales="pt-BR"
          source="date"
          label="Data"
        />
        {permissions &&
          (permissions.includes('SYS_ADMIN') ||
            permissions.includes('ROLE_PRE_CADASTRO_UPDATE')) && <EditButton />}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} className="botoesUpdate">
    <SaveButton />
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_PRE_CADASTRO_DELETE')) && <DeleteButton />}
  </Toolbar>
);

// eslint-disable-next-line consistent-return
const validaCPF = (value) => {
  if (!value) {
    return 'CPF inválido ou incompleto';
  }
  if (!cpf.isValid(value)) {
    return 'CPF inválido ou incompleto';
  }
};

export const PreCadastroEdit = (props) => {
  const { permissions } = props;
  return (
    <Edit title={<PreCadastroTitle />} {...props}>
      <SimpleForm
        decorators={[focusOnErrors]}
        redirect="list"
        toolbar={<CustomToolbar permissions={permissions} />}
      >
        <TextInput label="Nome" validate={required()} source="name" />
        <TextInput
          label="CPF"
          InputProps={{
            inputComponent: mascaraCPF,
          }}
          validate={[required(), validaCPF]}
          source="cpf"
        />
        <TextInput label="RG" validate={required()} source="rg" />
        <DateInput
          label="Data de Nascimento"
          validate={required()}
          source="birthdate"
        />
        <SelectInput
          source="gender"
          validate={required()}
          choices={[
            { id: 'Masculino', name: 'Masculino' },
            { id: 'Feminino', name: 'Feminino' },
          ]}
        />
        <TextInput
          label="E-mail"
          validate={[required(), email()]}
          source="email"
        />
        <TextInput
          InputProps={{
            inputComponent: mascaraTelefone,
          }}
          label="Telefone"
          validate={required()}
          source="phone"
        />
        <TextInput
          InputProps={{
            inputComponent: mascaraCelular,
          }}
          label="Celular"
          source="cellphone"
        />
        <CEPAuto />
        <BooleanInput label="Visualizar" source="seen" />
      </SimpleForm>
    </Edit>
  );
};

export const PreCadastroCreate = (props) => (
  <Create {...props}>
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <ImageInput
        validate={required()}
        source="pictures"
        label="Imagem"
        accept="image/*"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <DateInput
        validate={required()}
        label="Data Inicial"
        source="start_date"
      />
      <DateInput validate={required()} label="Data Final" source="end_date" />
      <TextInput label="Título" source="title" />
      <TextInput label="Descrição" source="description" />
      <BooleanInput label="Desabilitado ?" source="disabled" />
    </SimpleForm>
  </Create>
);

export default PreCadastro;
