import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import axios from 'axios';
import React, { useState } from 'react';

const ToggleDestaque = ({ record }) => {
  if (!record) return <div />;
  const [destaque, setDestaque] = useState(record.showFirst);
  const token = localStorage.getItem('token');

  const config = {
    headers: {
      store: record.store,
      authorization: `Bearer ${token}`,
    },
  };

  const handleToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/toggleDestaque/${record.id}`,
        config
      )
      .then((res) => {
        setDestaque(res.data);
      });
  };

  return (
    <div>
      <Button onClick={handleToggle}>
        {destaque ? (
          <Tooltip title="Desabilitar o Destaque">
            <StarIcon />
          </Tooltip>
        ) : (
          <Tooltip title="Habilitar o Destaque">
            <StarBorderIcon />
          </Tooltip>
        )}
      </Button>
    </div>
  );
};

export default ToggleDestaque;
