import cep from 'cep-promise';
import React, { useState, useEffect } from 'react';
import { TextInput, required } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import MaskedInput from 'react-text-mask';

function mascaraCEP(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
    />
  );
}

const CEPAuto = () => {
  const [procura, setProcura] = useState(false);
  const [erroCEP, setErroCEP] = useState(false);
  const [vaiProcurar, setVaiProcurar] = useState(false);

  // Para pegar os valores do form
  const formState = useFormState();
  // Para pegar o form desse componente
  const form = useForm();

  const ProcuraCEP = formState.values.cep;

  useEffect(() => {
    if (ProcuraCEP && ProcuraCEP.includes('_')) {
      setVaiProcurar(true);
    }

    if (vaiProcurar && ProcuraCEP && !ProcuraCEP.includes('_')) {
      setVaiProcurar(false);
      setProcura(true);
      form.batch(() => {
        form.change('state', '...');
        form.change('city', '...');
        form.change('neighborhood', '...');
        form.change('address', '...');
      });
      cep(ProcuraCEP.replace('-', '')).then(
        // Deu tudo ok
        (result) => {
          setProcura(false);
          setErroCEP(false);
          form.batch(() => {
            form.change('state', result.state);
            form.change('city', result.city);
            form.change('neighborhood', result.neighborhood);
            if (result.street.includes(' - ')) {
              form.change('address', result.street.split(' - ')[0]);
            } else {
              form.change('address', result.street);
            }
          });
        },
        // Deu algum erro
        () => {
          setProcura(false);
          setErroCEP(true);
          form.batch(() => {
            form.change('state', '');
            form.change('city', '');
            form.change('neighborhood', '');
            form.change('address', '');
          });
        }
      );
    }
  }, [ProcuraCEP]);

  return (
    <div className="CepDiv">
      <TextInput
        label="CEP"
        source="cep"
        helperText={erroCEP ? 'CEP inválido' : ''}
        InputProps={{
          inputComponent: mascaraCEP,
        }}
        validate={required()}
      />
      <TextInput label="Endereço" source="address" disabled={procura} />
      <TextInput label="Número" source="number" disabled={procura} />
      <TextInput
        validate={required()}
        label="Bairro"
        source="neighborhood"
        disabled={procura}
      />
      <TextInput validate={required()} label="Estado" source="state" disabled />
      <TextInput validate={required()} label="Cidade" source="city" disabled />
      <TextInput label="Complemento" source="complement" />
    </div>
  );
};
export default CEPAuto;
