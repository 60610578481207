import React from 'react';
import { CookiesProvider } from 'react-cookie';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

/* <React.StrictMode>
<App />
</React.StrictMode>, */

root.render(
  <BrowserRouter>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
