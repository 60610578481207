/* eslint-disable no-shadow */

import IconButton from '@material-ui/core/IconButton';
import MessageIcon from '@material-ui/icons/Message';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import React, { useState, useEffect } from 'react';
import { useFormState, useForm, useField } from 'react-final-form';

import ModalDescricao from './ModalDescricao';

const ImagePreview = ({ record = { destaque: 0 } }) => {
  if (!record.destaque) record.destaque = 0;
  if (!record.description) record.description = '';
  const { description, destaque: showFirst, src } = record;
  const [open, setOpen] = useState(false);
  const form = useForm();
  const formState = useFormState();
  const imagens = formState.values.pictures;
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  // Não é a melhor forma de se fazer isso mas por enquanto vai dar certo, hotfix para apresentar
  // ao cliente
  // Estamos forçando o update do componente

  if (record.undefined) {
    record.src = record.undefined;
    delete record.undefined;
    forceUpdate();
  }

  const openModal = () => {
    setOpen(true);
  };

  const destaque = (e) => {
    const temp = [...imagens];
    temp.forEach((antigo) => {
      if (antigo.src === src) {
        antigo.destaque = showFirst !== 1 ? 1 : 0;
      }
    });
    form.change('pictures', temp);
  };

  const handleClose = (tipo, description) => {
    if (tipo === 'salvar') {
      const temp = [...imagens];
      temp.forEach((antigo) => {
        if (antigo.src === src) {
          antigo.description = description;
        }
      });
      form.change('pictures', temp);
    }

    setOpen(false);
  };

  return (
    <>
      <div style={{ padding: '10px' }}>
        <IconButton
          className="showFirstButton"
          onClick={() => destaque(record.src)}
          aria-label="Destaque"
          title="Destaque"
          style={{ color: 'yellow', marginRight: '84px' }}
        >
          {showFirst === 1 ? <StarIcon /> : <StarBorderIcon />}
        </IconButton>
        <IconButton
          className="descriptionButton"
          onClick={() => openModal(record.src)}
          aria-label="Descrição"
          title="Descrição"
          style={{ color: '#0072ff', marginRight: '44px' }}
        >
          <MessageIcon />
        </IconButton>
        <img src={src} style={{ maxHeight: '10rem' }} alt={description} />
      </div>
      <ModalDescricao
        open={open}
        handleClose={handleClose}
        descricaoDefault={description}
      />
    </>
  );
};
export default ImagePreview;
