/* eslint-disable no-shadow */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import theme from '../../config/theme';
import background from '../../images/fundo-login.jpg';
import EsqueciSenha from './esqueci';
import FormLogin from './login';
import ResetarSenha from './resetar';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Switch>
          <Route path="/login">
            <FormLogin classes={classes} />
          </Route>
          <Route path="/esqueciSenha">
            <EsqueciSenha classes={classes} />
          </Route>
          <Route path="/resetarSenha">
            <ResetarSenha classes={classes} />
          </Route>
        </Switch>
      </Grid>
    </ThemeProvider>
  );
}
