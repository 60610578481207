import createDecorator from 'final-form-focus';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  required,
} from 'react-admin';

const focusOnErrors = createDecorator();

const PermissoesTitle = ({ record }) => (
  <span>Permissão {record ? `"${record.name}"` : ''}</span>
);

const PermissoesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

export const Permissoes = (props) => (
  <List title="Permissões" filters={<PermissoesFilter />} {...props}>
    <Datagrid>
      <TextField label="Permissão" source="role" />
      <TextField label="Nome" source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const PermissoesEdit = (props) => (
  <Edit title={<PermissoesTitle />} {...props}>
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <TextInput
        label="Permissão"
        validate={required()}
        source="role"
        fullWidth
      />
      <TextInput label="Nome" validate={required()} source="name" fullWidth />
    </SimpleForm>
  </Edit>
);

export const PermissoesCreate = (props) => (
  <Create {...props} title="Nova Permissão">
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <TextInput
        label="Permissão"
        validate={required()}
        source="role"
        fullWidth
      />
      <TextInput label="Nome" validate={required()} source="name" fullWidth />
    </SimpleForm>
  </Create>
);

export default Permissoes;
