import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  ReferenceArrayInput,
  SelectArrayInput,
  required,
  SelectInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
} from 'react-admin';
import { useFormState } from 'react-final-form';

const SelectPermissions = ({ ...props }) => {
  let index = 0;
  // Para pegar os valores do form
  const formState = useFormState();
  const stores = Array.from(localStorage.getItem('stores').split(','));
  const userStores = [];

  if (Object.keys(props.record).length !== 0 && index === 0) {
    index = props.record.permissionsArray.length;
  }

  if (
    formState.values.permissionsArray &&
    formState.values.permissionsArray.length > 0 &&
    index !== 0
  ) {
    formState.values.permissionsArray.forEach((store) => {
      if (store) userStores.push(store.storeUser);
    });
  }

  return (
    <div
      style={{
        width: '400px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <ArrayInput
        source="permissionsArray"
        validate={required()}
        label="Imobiliárias"
      >
        <SimpleFormIterator
          disableReordering
          disableAdd={
            formState.values.permissionsArray
              ? formState.values.permissionsArray.length >= stores.length
              : false
          }
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <ReferenceInput
                label="Imobiliária"
                source={getSource('storeUser')}
                record={scopedFormData}
                reference="stores"
                validate={required()}
                sort={{ field: 'id', order: 'ASC' }}
              >
                <SelectInput
                  disabled={Boolean(scopedFormData && scopedFormData.storeUser)}
                  optionText={(option) => {
                    if (
                      scopedFormData &&
                      scopedFormData.storeUser &&
                      scopedFormData.storeUser === option.id
                    ) {
                      option.disabled = true;
                    } else {
                      option.disabled = false;
                    }
                    return `${option.name}`;
                  }}
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ getSource, scopedFormData, formData, ...rest }) => (
              <ReferenceArrayInput
                style={{ width: '100%' }}
                label="Permissões"
                source={getSource('permissions')}
                record={scopedFormData}
                validate={required()}
                reference="permissions"
                filter={
                  scopedFormData && scopedFormData.storeUser
                    ? { store: scopedFormData.storeUser }
                    : null
                }
              >
                <SelectArrayInput
                  disabled={!(scopedFormData && scopedFormData.storeUser)}
                  optionText={(option) => {
                    if (
                      scopedFormData &&
                      scopedFormData.permissions &&
                      scopedFormData.permissions.includes(option.id)
                    )
                      return `✔ ${option.name}`;
                    return `${option.name}`;
                  }}
                />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};
export default SelectPermissions;
