import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useCallback } from 'react';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import CaracteristicaRapida from './CaracteristicaRapida';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'baseline',
  },
});

const CaracteristicaNew = (props) => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);
  const formState = useFormState();

  return (
    <div className={classes.root}>
      <ReferenceArrayInput key={version} {...props}>
        <SelectArrayInput
          optionValue="id"
          optionText={(option) => {
            if (
              formState.values.characteristicsList &&
              formState.values.characteristicsList.includes(option.id)
            )
              return `✔ ${option.name}`;
            return `${option.name}`;
          }}
        />
      </ReferenceArrayInput>

      <CaracteristicaRapida onChange={handleChange} />
    </div>
  );
};

export default CaracteristicaNew;
