import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { validate } from 'email-validator';
import React, { useState } from 'react';
import { useNotify, Notification, useRedirect } from 'react-admin';
import { Link } from 'react-router-dom';

import Copyright from '../../components/Copyright';
import auth from '../../services/auth';

export default function EsqueciSenha(props) {
  const [email, setEmail] = useState('');
  const { classes } = props;
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      if (validate(email)) {
        auth.resetarSenha({ email }).then(() => {
          notify('Um email de confirmação foi enviado!');

          redirect('/login');
        });
      } else {
        notify('E-mail inválido', 'warning');
      }
    } else {
      notify('Preencha o E-mail', 'warning');
    }
  };

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.back}>
        <Avatar style={{ margin: '10px' }}>
          <Link
            to="/login"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <ArrowBackIcon />
          </Link>
        </Avatar>
      </div>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Esqueci minha Senha
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Resetar Senha
          </Button>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
        <Notification />
      </div>
    </Grid>
  );
}
