import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory } from 'react-router-dom';

import Copyright from '../../components/Copyright';

export default function FormLogin(props) {
  const [rememberme, setRemember] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState(false);
  const { classes } = props;
  const login = useLogin();
  const history = useHistory();
  const notify = useNotify();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captcha) {
      notify('Verifique que você não é um robo!', 'warning');
      return;
    }

    if (email && password && captcha) {
      login({ email, password, rememberme, captcha }).then(
        () => {
          notify('Login efetuado com sucesso!');
          history.push('/');
        },
        (a) => {
          setCaptcha(false);
          window.grecaptcha.reset();
          if (a.message === 'Forbidden') {
            notify('Algo deu errado, tente novamente mais tarde', 'warning');
            return;
          }
          notify('E-mail ou Senha Inválido(s)', 'warning');
        }
      );
    } else if (!email) {
      notify('Preencha o E-mail', 'warning');
    } else {
      notify('Preencha a Senha', 'warning');
    }
  };

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Painel Administrativo
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="rememberme" color="primary" />}
            onChange={() => setRemember(!rememberme)}
            label="Mantenha-me logado"
          />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <ReCAPTCHA
              sitekey="6LegcoofAAAAAITLnqQhgYfrJ30fRk_WUQ-EIjvX"
              onChange={(value) => setCaptcha(value)}
              onErrored={() => setCaptcha(false)}
              onExpired={() => setCaptcha(false)}
            />
          </div>
          <Tooltip
            title={
              !captcha
                ? 'Verifique que você não é um robô para fazer login!'
                : ''
            }
          >
            <div>
              <Button
                disabled={!captcha}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Fazer Login
              </Button>
            </div>
          </Tooltip>
          <Grid container>
            <Grid item xs>
              <Link
                style={{ color: 'inherit', textDecoration: 'none' }}
                to="/esqueciSenha"
              >
                Esqueceu a senha?
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
        <Notification />
      </div>
    </Grid>
  );
}
