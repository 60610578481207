import createDecorator from 'final-form-focus';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  EmailField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  PasswordInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  email,
  BooleanInput,
  SimpleFormIterator,
  ArrayInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';

import SelectPermissions from './SelectPermissions';
import ToggleUsuario from './toggleUsuario';

import './style.css';

const focusOnErrors = createDecorator();

const UserTitle = ({ record }) => (
  <span>Usuário {record ? `"${record.name}"` : ''}</span>
);

const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_USUARIOS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const Usuarios = (props) => {
  const { permissions } = props;
  return (
    <List
      title="Usuários"
      bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      filters={<UsersFilter />}
      {...props}
    >
      <Datagrid>
        <ToggleUsuario label="Ativo" {...props} />
        <TextField label="Nome" source="name" />
        <EmailField label="E-mail" source="email" />
        {/* <ReferenceArrayField
          label="Permissões"
          reference="permissions"
          source="permissions"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField> */}
        {permissions &&
          (permissions.includes('SYS_ADMIN') ||
            permissions.includes('ROLE_USUARIOS_UPDATE')) && <EditButton />}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} className="botoesUpdate">
    <SaveButton />
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_USUARIOS_DELETE')) && <DeleteButton />}
  </Toolbar>
);

export const UserEdit = (props) => {
  const { permissions } = props;
  return (
    <Edit title={<UserTitle />} {...props}>
      <SimpleForm
        decorators={[focusOnErrors]}
        redirect="list"
        toolbar={<CustomToolbar permissions={permissions} />}
      >
        <TextInput validate={required()} label="Nome" source="name" />
        <TextInput
          validate={[required(), email()]}
          label="E-mail"
          source="email"
          autoComplete="email"
        />
        <PasswordInput
          label="Senha"
          source="password"
          autoComplete="current-password"
        />
        <SelectPermissions {...props} />
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = (props) => {
  const { permissions } = props;

  return (
    <Create {...props} title="Novo(a) Usuário(a)">
      <SimpleForm decorators={[focusOnErrors]} redirect="list">
        <TextInput validate={required()} label="Nome" source="name" />
        <TextInput
          validate={[required(), email()]}
          label="E-mail"
          source="email"
          autoComplete="email"
        />
        <PasswordInput
          validate={required()}
          label="Senha"
          source="password"
          autoComplete="new-password"
        />
        <SelectPermissions {...props} />
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Create>
  );
};

export default Usuarios;
