import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';

const ModalDescricao = ({ open, handleClose, descricaoDefault }) => {
  const [descricao, setDescricao] = useState('');

  useEffect(() => {
    setDescricao(descricaoDefault || '');
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Descrição</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Escreva a descrição da imagem no campo abaixo
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Descrição"
          fullWidth
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose('cancelar', descricao)}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          onClick={() => handleClose('salvar', descricao)}
          color="primary"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalDescricao;
