import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json, application/zip',
    });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('store', localStorage.getItem('store'));
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL,
  httpClient
);

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    if (!file) reject(new Error('No file'));

    if (file && file.src) {
      if ('src' in file)
        if (file.src.indexOf('blob') === -1) {
          resolve(file.src);
        }

      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file.rawFile);
    } else {
      resolve(file);
    }
  });

const createNoWatermark = (newPictures, resource, params) =>
  Promise.all(newPictures.map(convertFileToBase64))
    .then((base64Pictures) =>
      base64Pictures.map((picture64, index) => ({
        src: picture64,
        description: newPictures[index].description,
        showFirst: newPictures[index].destaque,
      }))
    )
    .then((transformedNewPictures) =>
      dataProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          pictures: [...transformedNewPictures],
        },
      })
    );

const updateNoWatermark = (newPictures, resource, params) =>
  Promise.all(newPictures.map(convertFileToBase64))
    .then((base64Pictures) =>
      base64Pictures.map((picture64, index) => ({
        src: picture64,
        description: newPictures[index].description,
        showFirst: newPictures[index].destaque,
      }))
    )
    .then((transformedNewPictures) =>
      dataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          pictures: [...transformedNewPictures],
        },
      })
    );

const createWatermark = (newPictures, resource, params) =>
  convertFileToBase64(params.data.watermark).then((watermarkImage) =>
    Promise.all(newPictures.map(convertFileToBase64))
      .then((base64Pictures) =>
        base64Pictures.map((picture64, index) => ({
          src: picture64,
          description: newPictures[index].description,
          showFirst: newPictures[index].destaque,
        }))
      )
      .then((transformedNewPictures) =>
        dataProvider.create(resource, {
          ...params,
          data: {
            ...params.data,
            pictures: [...transformedNewPictures],
            watermark: { src: watermarkImage },
          },
        })
      )
  );

const updateWatermark = (newPictures, resource, params) =>
  convertFileToBase64(params.data.watermark).then((watermarkImage) =>
    Promise.all(newPictures.map(convertFileToBase64))
      .then((base64Pictures) =>
        base64Pictures.map((picture64, index) => ({
          src: picture64,
          description: newPictures[index].description,
          showFirst: newPictures[index].destaque,
        }))
      )
      .then((transformedNewPictures) =>
        dataProvider.update(resource, {
          ...params,
          data: {
            ...params.data,
            pictures: [...transformedNewPictures],
            watermark: { src: watermarkImage },
          },
        })
      )
  );

const myDataProvider = {
  ...dataProvider,

  update: (resource, params) => {
    if (!params.data.pictures) {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }

    // Freshly dropped pictures are File objects and must be converted to base64 strings
    if (!Array.isArray(params.data.pictures)) {
      // eslint-disable-next-line no-param-reassign
      params.data.pictures = [params.data.pictures];
    }

    const newPictures = params.data.pictures;

    if (params.data.watermark)
      return updateWatermark(newPictures, resource, params);

    return updateNoWatermark(newPictures, resource, params);
  },

  create: (resource, params) => {
    if (!params.data.pictures) {
      // fallback to the default implementation
      return dataProvider.create(resource, params);
    }

    // Freshly dropped pictures are File objects and must be converted to base64 strings
    if (!Array.isArray(params.data.pictures)) {
      // eslint-disable-next-line no-param-reassign
      params.data.pictures = [params.data.pictures];
    }

    const newPictures = params.data.pictures;

    if (params.data.watermark)
      return createWatermark(newPictures, resource, params);

    return createNoWatermark(newPictures, resource, params);
  },
};

export default myDataProvider;
