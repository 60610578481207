import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LaunchIcon from '@material-ui/icons/Launch';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import createDecorator from 'final-form-focus';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  SelectInput,
  required,
} from 'react-admin';

import ToggleMidiaSocial from './toggleMidiaSocial';

import './style.css';

const focusOnErrors = createDecorator();

const SocialTitle = ({ record }) => (
  <span>Mídia social {record ? `"${record.name}"` : ''}</span>
);

const SocialFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const socialMediaIcons = [
  { id: 'FacebookIcon', icon: <FacebookIcon />, name: 'Facebook' },
  { id: 'InstagramIcon', icon: <InstagramIcon />, name: 'Instagram' },
  { id: 'LinkedInIcon', icon: <LinkedInIcon />, name: 'LinkedIn' },
  { id: 'TwitterIcon', icon: <TwitterIcon />, name: 'Twitter' },
  { id: 'YouTubeIcon', icon: <YouTubeIcon />, name: 'YouTube' },
];

const IconField = ({ record }) => {
  if (!record) return <div />;

  return (
    <span className="iconesMidiasSociais">
      {record.icon} {record.name}
    </span>
  );
};

const UrlField = ({ record }) => {
  if (!record) return <div />;

  return (
    <a
      href={record.link}
      target="_blank"
      rel="noopener noreferrer"
      className="linkIconesMidiasSociais"
    >
      {record.link}
      <LaunchIcon className="iconeLinkMidasSociais" />
    </a>
  );
};

const IconDisplay = ({ record }) => {
  if (!record) return <div />;

  return (
    <span className="iconesMidiasSociais">
      {socialMediaIcons.find((x) => x.id === record.icon).icon}
    </span>
  );
};

export const Social = (props) => (
  <List title="Mídias Sociais" filters={<SocialFilter />} {...props}>
    <Datagrid>
      <ToggleMidiaSocial label="Habilitado" {...props} />
      <TextField label="Nome" source="name" />
      <IconDisplay label="Ícone" source="icon" />
      <UrlField label="Link" source="link" />
      <EditButton />
    </Datagrid>
  </List>
);

const Infos = () => (
  <div>
    <TextInput
      label="Nome"
      validate={required()}
      source="name"
      style={{ marginRight: '10px' }}
    />
    <SelectInput
      source="icon"
      label="Ícone"
      choices={socialMediaIcons}
      validate={required()}
      optionText={<IconField />}
    />
  </div>
);

export const SocialEdit = (props) => (
  <Edit title={<SocialTitle />} {...props}>
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <Infos />
      <TextInput label="Link" validate={required()} source="link" fullWidth />
    </SimpleForm>
  </Edit>
);

export const SocialCreate = (props) => (
  <Create {...props} title="Nova Mídia Social">
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <Infos />
      <TextInput label="Link" validate={required()} source="link" fullWidth />
    </SimpleForm>
  </Create>
);

export default Social;
