import createDecorator from 'final-form-focus';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
} from 'react-admin';

const focusOnErrors = createDecorator();

const TiposTitle = ({ record }) => (
  <span>Tipo {record ? `"${record.name}"` : ''}</span>
);

const TiposFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_IMOVEIS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const Tipos = (props) => {
  const { permissions } = props;

  return (
    <List
      title="Tipos"
      bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      filters={<TiposFilter />}
      {...props}
    >
      <Datagrid>
        <TextField label="Nome" source="name" />
        {permissions &&
          (permissions.includes('SYS_ADMIN') ||
            permissions.includes('ROLE_TIPOS_NEGOCIO_UPDATE')) && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} className="botoesUpdate">
    <SaveButton />
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_TIPOS_NEGOCIO_DELETE')) && <DeleteButton />}
  </Toolbar>
);

export const TiposEdit = (props) => {
  const { permissions } = props;

  return (
    <Edit title={<TiposTitle />} {...props}>
      <SimpleForm
        decorators={[focusOnErrors]}
        redirect="list"
        toolbar={<CustomToolbar permissions={permissions} />}
      >
        <TextInput label="Nome" validate={required()} source="name" />
      </SimpleForm>
    </Edit>
  );
};

export const TiposCreate = (props) => (
  <Create {...props} title="Novo Tipo">
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <TextInput label="Nome" validate={required()} source="name" />
    </SimpleForm>
  </Create>
);

export default Tipos;
