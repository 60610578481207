import createDecorator from 'final-form-focus';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  required,
} from 'react-admin';

const focusOnErrors = createDecorator();

const CaracteristicasTitle = ({ record }) => (
  <span>Caracteristica {record ? `"${record.name}"` : ''}</span>
);

const CaracteristicasFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

export const Caracteristicas = (props) => (
  <List title="Características" filters={<CaracteristicasFilter />} {...props}>
    <Datagrid>
      <TextField label="Nome" source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CaracteristicasEdit = (props) => (
  <Edit title={<CaracteristicasTitle />} {...props}>
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <TextInput label="Nome" validate={required()} source="name" fullWidth />
    </SimpleForm>
  </Edit>
);

export const CaracteristicasCreate = (props) => (
  <Create {...props} title="Nova Característica">
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <TextInput label="Nome" validate={required()} source="name" fullWidth />
    </SimpleForm>
  </Create>
);

export default Caracteristicas;
