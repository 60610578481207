import { Typography, Link } from '@material-ui/core';
import React from 'react';

const Copyright = () => (
  <>
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright ©{' '}
      <Link color="inherit" target="_blank" href="https://tytotech.com.br/">
        Tytotech
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
    <div style={{ padding: '5px', textAlign: 'center', marginTop: '25px' }}>
      v{process.env.REACT_APP_VERSION}
    </div>
  </>
);
export default Copyright;
