import Tooltip from '@material-ui/core/Tooltip';
import FacebookIcon from '@material-ui/icons/Facebook';
import React from 'react';
import { FacebookShareButton } from 'react-share';

const FacebookShare = ({ record }) => {
  if (!record) return <div />;
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip title={`Compartilhar imóvel REF${record.id} no Facebook`}>
        <FacebookShareButton
          url={`${process.env.REACT_APP_API_URL}/imoveisCrawler/${record.id}?url=${record.website_link}`}
          className="shareImovel"
        >
          <FacebookIcon />
        </FacebookShareButton>
      </Tooltip>
    </div>
  );
};

export default FacebookShare;
