import { format } from 'date-fns';
import createDecorator from 'final-form-focus';
import React from 'react';
import {
  List,
  Datagrid,
  DateField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  ImageField,
  DateInput,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  BooleanInput,
} from 'react-admin';

import ToggleBanner from './toggleBanner';

const focusOnErrors = createDecorator();

const BannersTitle = ({ record }) => {
  const texto = record.end_date
    ? `até ${format(new Date(record.end_date), 'd/M/yyyy')}`
    : '';
  return (
    <span>
      Banner{' '}
      {record
        ? `de ${format(new Date(record.start_date), 'd/M/yyyy')} ${texto}`
        : ''}
    </span>
  );
};

const BannersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_BANNERS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const Banners = (props) => {
  const { permissions } = props;

  return (
    <List
      title="Banners"
      bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      filters={<BannersFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <ToggleBanner label="Ativo" {...props} />
        <ImageField source="image" label="Imagem" title="Banner" />
        <DateField
          options={{ timeZone: 'UTC' }}
          locales="pt-BR"
          source="start_date"
          label="Data Inicial"
        />
        <DateField
          options={{ timeZone: 'UTC' }}
          locales="pt-BR"
          source="end_date"
          label="Data Final"
        />
        <TextField label="Título" source="title" />
        <TextField label="Descrição" source="description" />
        {permissions &&
          (permissions.includes('SYS_ADMIN') ||
            permissions.includes('ROLE_BANNERS_UPDATE')) && <EditButton />}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} className="botoesUpdate">
    <SaveButton />
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_BANNERS_DELETE')) && <DeleteButton />}
  </Toolbar>
);

const Datas = (props) => {
  const { timeNow } = props;

  return (
    <div>
      <DateInput
        validate={required()}
        label="Data Inicial"
        source="start_date"
        style={{ marginRight: '10px' }}
        value={timeNow}
        defaultValue={timeNow}
      />
      <DateInput label="Data Final" source="end_date" />
    </div>
  );
};

export const BannersEdit = (props) => {
  const { permissions } = props;

  return (
    <Edit title={<BannersTitle />} {...props}>
      <SimpleForm
        decorators={[focusOnErrors]}
        redirect="list"
        toolbar={<CustomToolbar permissions={permissions} />}
      >
        <ImageInput
          validate={required()}
          source="pictures"
          label="Imagem"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <Datas />
        <TextInput label="Título" fullWidth source="title" />
        <TextInput label="Descrição" fullWidth source="description" />
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Edit>
  );
};

export const BannersCreate = (props) => {
  const agora = new Date();

  return (
    <Create {...props}>
      <SimpleForm decorators={[focusOnErrors]} redirect="list">
        <ImageInput
          validate={required()}
          source="pictures"
          label="Imagem"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <Datas timeNow={agora} />
        <TextInput label="Título" fullWidth source="title" />
        <TextInput label="Descrição" fullWidth source="description" />
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Create>
  );
};

export default Banners;
