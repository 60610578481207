import Cookies from 'universal-cookie';

const cookies = new Cookies();

const authProvider = {
  login: ({ email, password, rememberme, captcha }) => {
    let token = null;
    const request = new Request(`${process.env.REACT_APP_API_URL}/login`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({ email, password, rememberme, captcha }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        token = response.token;
        if (rememberme)
          cookies.set('token', token, {
            path: '/',
            httpOnly: true,
            secure: true,
            sameSite: 'strict',
          });
        localStorage.setItem('token', token);
        localStorage.setItem('stores', response.stores);
        if (response.stores.length === 1) {
          cookies.set('store', response.userStores[0].name, {
            path: '/',
            secure: true,
            sameSite: 'strict',
          });
          localStorage.setItem('store', response.userStores[0].id);
        }
        if (token) {
          return Promise.resolve();
        }
        return Promise.reject();
      });
  },

  logout: () => {
    cookies.remove('token');
    cookies.remove('store');
    localStorage.removeItem('token');
    localStorage.removeItem('stores');
    localStorage.removeItem('store');
    return Promise.resolve();
  },

  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      cookies.remove('token');
      cookies.remove('store');
      localStorage.removeItem('token');
      localStorage.removeItem('stores');
      localStorage.removeItem('store');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),

  getPermissions: () => {
    const token = localStorage.getItem('token');
    const store = localStorage.getItem('store');
    const locations = ['esqueciSenha', 'resetarSenha'];

    if (!token) {
      const result = locations.map((local) => {
        if (window.location.href.includes(local)) {
          return true;
        }
        return false;
      });
      if (result) return Promise.resolve('');
    }

    const request = new Request(
      `${process.env.REACT_APP_API_URL}/getPermissionsUser`,
      {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ token, store }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ permissions }) =>
        permissions ? Promise.resolve(permissions) : Promise.reject()
      );
  },

  resetarSenha: ({ email }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_URL}/passwordReset`,
      {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ email }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
      })
      .then(() => {
        Promise.resolve();
      });
  },

  verificaCodigo: ({ code }) => {
    const request = new Request(`${process.env.REACT_APP_API_URL}/verifyCode`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({ code }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => (result ? Promise.resolve() : Promise.reject()));
  },

  novaSenha: ({ token, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_URL}/passwordReset`,
      {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ token, password }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => (result ? Promise.resolve() : Promise.reject()));
  },
};

export default authProvider;
