import { Divider, Tooltip } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DefaultIcon from '@material-ui/icons/ViewList';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import { useSelector } from 'react-redux';

import './style.css';

const Menu = ({ onMenuClick, logout }) => {
  const { permissions } = usePermissions();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  let naoMostrar = ['permissions', 'deal_types', 'types', 'stores'];
  if (permissions && permissions.includes('SYS_ADMIN')) {
    naoMostrar = [];
  } else if (permissions && permissions.includes('ROLE_LOJAS_LIST')) {
    naoMostrar = ['permissions', 'deal_types', 'types'];
  }

  return (
    <div style={{ width: 'inherit' }}>
      <MenuItemLink
        to="/"
        primaryText="Dashboard"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <Divider />

      {resources.map((resource) => {
        if (!naoMostrar.includes(resource.name))
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) || resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          );
        return true;
      })}
      <Divider />
      <Tooltip title="Sair" placement="right">
        {logout}
      </Tooltip>
      <Divider />
      <div style={{ padding: '5px', textAlign: 'center', marginTop: '25px' }}>
        v{process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
};

export default Menu;
