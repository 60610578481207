import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import React, { useState } from 'react';

const ToggleInteresse = ({ record }) => {
  if (!record) return <div />;
  const [disabled, setDisabled] = useState(record.seen);
  const token = localStorage.getItem('token');

  const config = {
    headers: {
      store: record.store,
      authorization: `Bearer ${token}`,
    },
  };

  const handleToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/ToggleInteresse/${record.id}`,
        config
      )
      .then((res) => {
        setDisabled(res.data);
      });
  };

  return (
    <div>
      <Button onClick={handleToggle}>
        {disabled ? (
          <Tooltip title="Desver o Interesse">
            <VisibilityIcon />
          </Tooltip>
        ) : (
          <Tooltip title="Visualizar o Interesse">
            <VisibilityOffIcon />
          </Tooltip>
        )}
      </Button>
    </div>
  );
};

export default ToggleInteresse;
