/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import React, { useState, useEffect } from 'react';
import { AppBar, useDataProvider } from 'react-admin';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  aligncontentcenter: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '10px',
  },
});

const MyAppBar = (props) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [cookies, setCookie] = useCookies([]);
  const [lojas, setLojas] = useState([]);
  const [atual, setAtual] = useState(cookies.store);
  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem('stores')) {
      dataProvider
        .getMany('user_stores', { ids: localStorage.getItem('stores') })
        .then(({ data }) => {
          setLojas(data);
          if (data.length > 1) {
            if (!cookies.store) {
              setOpen(true);
            }
            setAtual(cookies.store ? cookies.store : '');
          }
          return true;
        });
    }
  }, []);

  const handleChange = (event, novo) => {
    setCookie('store', event.target.value, { path: '/' });
    localStorage.setItem('store', novo.props.name);
    // Melhorar isso
    window.location.reload();
  };

  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      <div className={classes.aligncontentcenter}>
        <Typography
          className={classes.marginRight}
          variant="h6"
          color="inherit"
          id="lojaNavbar"
        >
          {atual}
        </Typography>
        {lojas.length > 1 && (
          <Tooltip title="Trocar de imobiliária">
            <Link
              style={{ padding: '12px' }}
              className={classes.aligncontentcenter}
              href="#"
              onClick={() => setOpen(true)}
              color="inherit"
            >
              <CompareArrowsIcon />
            </Link>
          </Tooltip>
        )}
      </div>
      <Modal open={open} className="modalSelectLoja">
        <div className="modalContainer">
          <InputLabel id="loja">Selecione sua Imobiliária</InputLabel>
          <Select
            labelId="loja"
            id="selectLoja"
            onChange={handleChange}
            style={{ width: '100%' }}
            value=""
          >
            {lojas.map((loja) => (
              <MenuItem
                key={loja.id}
                name={loja.id}
                value={`${loja.name} - ${loja.state}`}
              >
                {loja.name} - {loja.state}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Modal>
    </AppBar>
  );
};

export default MyAppBar;
