import portugueseMessages from '@henriko/ra-language-portuguese';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FacebookIcon from '@material-ui/icons/Facebook';
import UserIcon from '@material-ui/icons/Group';
import HouseIcon from '@material-ui/icons/House';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import MyLayout from '../../components/Layout';
import theme from '../../config/theme';
import authProvider from '../../services/auth';
import dataProvider from '../../services/dataProvider';
import { Banners, BannersEdit, BannersCreate } from '../Banners';
import {
  Caracteristicas,
  CaracteristicasEdit,
  CaracteristicasCreate,
} from '../Caracteristicas';
import Dashboard from '../Dashboard';
import { Imoveis, ImoveisEdit, ImoveisCreate } from '../Imoveis';
import { Interesse, InteresseEdit } from '../Interesse';
import Login from '../Login';
import { Lojas, LojasEdit, LojasCreate } from '../Lojas';
import { Permissoes, PermissoesEdit, PermissoesCreate } from '../Permissoes';
import { PreCadastro, PreCadastroEdit } from '../PreCadastro';
import { Social, SocialEdit, SocialCreate } from '../Social';
import { Tipos, TiposEdit, TiposCreate } from '../Tipos';
import { Categorias, CategoriasEdit, CategoriasCreate } from '../Tipos_Imoveis';
import { Usuarios, UserEdit, UserCreate } from '../Usuarios';

import './style.css';

const messages = {
  pt: portugueseMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'pt', {
  allowMissing: true,
});

function App() {
  return (
    <Admin
      disableTelemetry
      theme={theme}
      layout={MyLayout}
      title="Central de Imobiliárias - Admin"
      dashboard={Dashboard}
      loginPage={Login}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={dataProvider}
      customRoutes={[
        <Route exact path="/esqueciSenha" component={Login} noLayout />,
        <Route exact path="/resetarSenha" component={Login} noLayout />,
      ]}
    >
      {(permissions) => [
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_USUARIOS_LIST') ? (
          <Resource
            name="users"
            options={{ label: 'Usuários' }}
            icon={UserIcon}
            list={Usuarios}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_USUARIOS_UPDATE')
                ? UserEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_USUARIOS_ADD')
                ? UserCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_IMOVEIS_LIST') ? (
          <Resource
            name="properties"
            options={{ label: 'Imóveis' }}
            icon={HouseIcon}
            list={Imoveis}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_IMOVEIS_UPDATE')
                ? ImoveisEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_IMOVEIS_ADD')
                ? ImoveisCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_BANNERS_LIST') ? (
          <Resource
            name="banners"
            options={{ label: 'Banners' }}
            icon={ViewCarouselIcon}
            list={Banners}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_BANNERS_UPDATE')
                ? BannersEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_BANNERS_ADD')
                ? BannersCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_LOJAS_LIST') ? (
          <Resource
            name="stores"
            options={{ label: 'Imobiliárias' }}
            icon={StorefrontIcon}
            list={Lojas}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_LOJAS_UPDATE')
                ? LojasEdit
                : null
            }
            create={permissions.includes('SYS_ADMIN') ? LojasCreate : null}
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_PRE_CADASTRO_LIST') ? (
          <Resource
            name="costumer"
            options={{ label: 'Pré Cadastro' }}
            icon={AssignmentIcon}
            list={PreCadastro}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_PRE_CADASTRO_UPDATE')
                ? PreCadastroEdit
                : null
            }
            create={null}
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_MIDIA_SOCIAL_LIST') ? (
          <Resource
            name="social_media"
            options={{ label: 'Mídias Sociais' }}
            icon={FacebookIcon}
            list={Social}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_MIDIA_SOCIAL_UPDATE')
                ? SocialEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_MIDIA_SOCIAL_ADD')
                ? SocialCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_CARACTERISTICAS_LIST') ? (
          <Resource
            name="characteristics"
            options={{ label: 'Características' }}
            list={Caracteristicas}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_CARACTERISTICAS_UPDATE')
                ? CaracteristicasEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_CARACTERISTICAS_ADD')
                ? CaracteristicasCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_INTERESSES_LIST') ? (
          <Resource
            name="contact"
            options={{ label: 'Interesse' }}
            icon={NotificationImportantIcon}
            list={Interesse}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_INTERESSES_UPDATE')
                ? InteresseEdit
                : null
            }
            create={null}
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_TIPOS_LIST') ? (
          <Resource
            name="types"
            options={{ label: 'Tipos' }}
            list={Tipos}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_TIPOS_UPDATE')
                ? TiposEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_TIPOS_UPDATE')
                ? TiposCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_TIPOS_NEGOCIO_LIST') ? (
          <Resource
            name="deal_types"
            options={{ label: 'Categorias' }}
            list={Categorias}
            edit={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_TIPOS_NEGOCIO_UPDATE')
                ? CategoriasEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
              permissions.includes('ROLE_TIPOS_NEGOCIO_UPDATE')
                ? CategoriasCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN')
          ? [
              <Resource
                name="permissions"
                options={{ label: 'Permissões' }}
                list={Permissoes}
                edit={PermissoesEdit}
                create={PermissoesCreate}
              />,
            ]
          : [
              <Resource name="permissions" />,
              <Resource name="types" />,
              <Resource name="deal_types" />,
            ],
      ]}
    </Admin>
  );
}

export default App;
