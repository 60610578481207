import axios from 'axios';
import createDecorator from 'final-form-focus';
import RichTextInput from 'ra-input-rich-text';
import { React, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  ImageInput,
  ImageField,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  RichTextField,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  number,
  minValue,
  BooleanInput,
  NumberInput,
  Button,
} from 'react-admin';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import CaracteristicaNew from './CaracteristicaNew';
import CEPAuto from './CEPAuto';
import FacebookShare from './FacebookShare';
import ImagePreview from './ImagePreview';
import ToggleDestaque from './toggleDestaque';
import ToggleImovel from './toggleImovel';
import ToggleImovelPrivacidade from './togglePrivate';

import './style.css';

const focusOnErrors = createDecorator();

const ImoveisTitle = ({ record }) => {
  if (record.address) {
    return (
      <span>Imóvel {record ? `"${record.cep} - ${record.number}"` : ''}</span>
    );
  }
  return <span>Imóvel {record ? `${record.id}` : ''}</span>;
};

const ImoveisFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
    <ReferenceInput
      label="Tipo de Negócio"
      source="deal_type"
      reference="deal_types"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Tipo de Imóvel"
      source="type"
      reference="types"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const Optionals = () => (
  <div className="imovelOpcionais">
    <NumberInput
      validate={[number(), minValue(0)]}
      min={0}
      label="Metros quadrados"
      source="m2"
    />
    <NumberInput
      validate={[number(), minValue(0)]}
      min={0}
      label="M² construídos"
      source="constructed_m2"
    />
    <NumberInput
      validate={[number(), minValue(0)]}
      min={0}
      max={99}
      label="Quartos"
      source="bedrooms"
    />
    <NumberInput
      validate={[number(), minValue(0)]}
      min={0}
      max={99}
      label="Suítes"
      source="suites"
    />
    <NumberInput
      validate={[number(), minValue(0)]}
      min={0}
      max={99}
      label="Banheiros"
      source="bathrooms"
    />
    <NumberInput
      validate={[number(), minValue(0)]}
      min={0}
      max={99}
      label="Vagas Estacionamento"
      source="parking"
    />
  </div>
);

const Tipos = () => (
  <div className="tiposImovel">
    <ReferenceInput
      validate={required()}
      label="Tipo de Negócio"
      source="deal_type"
      reference="deal_types"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      validate={required()}
      label="Tipo de Imóvel"
      source="type"
      reference="types"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </div>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_IMOVEIS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const Imoveis = (props) => {
  const { permissions } = props;
  return (
    <List
      title="Imóveis"
      bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      filters={<ImoveisFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <TextField label="REF" source="id" />
        <ToggleDestaque label="Destaque" {...props} />
        {/* <ToggleImovelPrivacidade label="Mostrar na Central" {...props} /> */}
        <FacebookShare label="Compartilhar no Facebook" {...props} />
        <ToggleImovel label="Habilitado" {...props} />
        {permissions &&
          (permissions.includes('SYS_ADMIN') ||
            permissions.includes('ROLE_IMOVEIS_UPDATE')) && <EditButton />}
        {/* <TextField label="CEP" source="cep" /> */}
        <TextField label="Endereço" source="address" />
        <TextField label="Número" source="number" />
        {/* <TextField label="Bairro" source="neighborhood" /> */}
        <TextField label="Cidade" source="city" />
        <TextField label="Estado" source="state" />
        {/* <TextField label="Complemento" source="complement" /> */}
        {/* <RichTextField label="Descrição" source="description" /> */}
        {/* <RichTextField label="Observações" source="observations" /> */}
        <ReferenceField
          label="Tipo de Negócio"
          source="deal_type"
          reference="deal_types"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Tipo de Imóvel"
          source="type"
          reference="types"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} className="botoesUpdate">
    <SaveButton />
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_IMOVEIS_DELETE')) && <DeleteButton />}
  </Toolbar>
);

const defaultMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: false,
  integerLimit: 10,
  allowNegative: false,
  allowLeadingZeroes: false,
};

function mascaraDinheiro(props) {
  const { inputRef, ...other } = props;
  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      inputMode="numeric"
      mask={currencyMask}
    />
  );
}

export const ImoveisEdit = (props) => {
  const { permissions } = props;
  const token = localStorage.getItem('token');
  const store = localStorage.getItem('store');
  const [disabled, setDisabled] = useState(false);

  const config = {
    headers: {
      store,
      authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const id = window.location.href.split('/').pop();

  const handleClick = (e) => {
    setDisabled(true);
    e.target.innerText = 'Baixando, aguarde...';

    axios
      .get(`${process.env.REACT_APP_API_URL}/properties/${id}/download`, config)
      .then((response) => {
        setDisabled(false);
        e.target.innerText = 'FAZER DOWNLOAD DAS IMAGENS';

        // Force download
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = `imagens_imovel_${id}.zip`;
        link.click();
      });
  };

  return (
    <Edit title={<ImoveisTitle />} {...props}>
      <SimpleForm
        decorators={[focusOnErrors]}
        redirect="list"
        toolbar={<CustomToolbar permissions={permissions} />}
      >
        <BooleanInput
          label="Colocar marca D'água?"
          source="watermarkEnabled"
          defaultValue
        />
        <Button
          disabled={disabled}
          onClick={handleClick}
          variant="outlined"
          label="Fazer download das imagens"
        />
        <ImageInput
          validate={required()}
          source="pictures"
          multiple
          label="Fotos do Imóvel"
          accept="image/*"
        >
          <ImagePreview {...props} />
        </ImageInput>
        <TextInput
          validate={required()}
          label="Valor"
          source="price"
          style={{ width: '206px' }}
          InputProps={{
            inputComponent: mascaraDinheiro,
          }}
        />
        <CEPAuto {...props} />
        <RichTextInput
          validate={required()}
          label="Descrição"
          source="description"
          required
        />
        <RichTextInput label="Observações" source="observations" />
        <BooleanInput label="Destaque?" source="showFirst" />
        <BooleanInput label="Desabilitado?" source="disabled" />
        <BooleanInput
          label="Mostrar apenas na minha imobiliária?"
          source="private_property"
        />
        <CaracteristicaNew
          fullWidth
          label="Características"
          source="characteristicsList"
          reference="characteristics"
          style={{ marginRight: '10px' }}
        />
        <Tipos />
        <Optionals />
        <TextInput label="Link do youtube" source="youtube_link" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export const ImoveisCreate = (props) => (
  <Create {...props} title="Novo Imóvel">
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <BooleanInput
        label="Colocar marca D'água?"
        source="watermarkEnabled"
        defaultValue
      />
      <ImageInput
        validate={required()}
        source="pictures"
        multiple
        label="Fotos do Imóvel"
        accept="image/*"
      >
        <ImagePreview {...props} />
      </ImageInput>
      <TextInput
        validate={required()}
        label="Valor"
        source="price"
        style={{ width: '206px' }}
        InputProps={{
          inputComponent: mascaraDinheiro,
        }}
      />
      <CEPAuto />
      <RichTextInput
        validate={required()}
        label="Descrição"
        source="description"
        required
      />
      <RichTextInput label="Observações" source="observations" />
      <BooleanInput label="Destaque?" source="showFirst" />
      <BooleanInput label="Desabilitado?" source="disabled" />
      <BooleanInput
        label="Mostrar apenas na minha imobiliária?"
        source="private_property"
      />
      <CaracteristicaNew
        fullWidth
        label="Características"
        source="characteristicsList"
        reference="characteristics"
        style={{ marginRight: '10px' }}
      />
      <Tipos />
      <Optionals />
      <TextInput label="Link do youtube" source="youtube_link" fullWidth />
    </SimpleForm>
  </Create>
);

export default Imoveis;
