import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useState } from 'react';
import { useNotify, Notification, useRedirect } from 'react-admin';
import { Link } from 'react-router-dom';

import Copyright from '../../components/Copyright';
import auth from '../../services/auth';

export default function ResetarSenha(props) {
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCode, setShowCode] = useState(true);
  const [showPasswod, setShowPassword] = useState(false);
  const { classes } = props;
  const token = window.location.href.split('?')[1];
  const notify = useNotify();
  const redirect = useRedirect();

  if (!token) {
    redirect('/login');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password || !newPassword || password !== newPassword) {
      notify('As senhas não coincidem', 'warning');
    } else if (password === newPassword) {
      if (password.length < 6) {
        notify('A senha precisa ser maior do que 6 caracteres', 'warning');
      } else {
        auth.novaSenha({ token, password }).then(
          () => {
            notify(
              'Sua senha foi alterada com sucesso, faça o login novamente!'
            );
            redirect('/login');
          },
          () => {
            notify(
              'Código inválido, verifique seu E-mail novamente!',
              'warning'
            );
            redirect('/login');
          }
        );
      }
    }
  };

  const controlaCodigo = (e) => {
    e.preventDefault();
    if (!code) {
      notify('Digite o código enviado para o seu E-mail', 'warning');
    } else {
      auth.verificaCodigo({ code }).then(
        () => {
          setShowCode(false);
          setShowPassword(true);
        },
        () => {
          notify('Código inválido, verifique novamente seu E-mail', 'warning');
          redirect('/login');
        }
      );
    }
  };

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Avatar style={{ margin: '10px' }}>
        <Link to="/login" style={{ color: 'inherit', textDecoration: 'none' }}>
          <ArrowBackIcon />
        </Link>
      </Avatar>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Esqueci minha Senha
        </Typography>
        <form className={classes.form} onSubmit={controlaCodigo} noValidate>
          <div style={{ display: showCode ? 'block' : 'none' }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              label="Código de Verificação"
              name="code"
              type="text"
              autoFocus
              value={code}
              onChange={(e) => setCode(e.target.value.toUpperCase())}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button color="primary" variant="contained" type="submit">
                <NavigateNextIcon />
              </Button>
            </div>
          </div>
        </form>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <div style={{ display: showPasswod ? 'block' : 'none' }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Nova Senha"
              name="password"
              autoComplete="password"
              type="password"
              autoFocus
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="newPassword"
              label="Confirmar Nova Senha"
              name="newPassword"
              type="password"
              autoComplete="new-password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Resetar Senha
            </Button>
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
        <Notification />
      </div>
    </Grid>
  );
}
