import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

function CaracteristicaRapida({ onChange }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('characteristics');
  const notify = useNotify();
  const form = useForm();
  const formState = useFormState();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          if (!formState.values.characteristicsList) {
            formState.values.characteristicsList = [data.id];
          } else {
            formState.values.characteristicsList.push(data.id);
          }
          onChange();
          setShowDialog(false);
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Criar Característica"
      >
        <DialogTitle>Criar Característica</DialogTitle>

        <FormWithRedirect
          resource="characteristics"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput
                  label="Nome"
                  source="name"
                  validate={required()}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default CaracteristicaRapida;
