import createDecorator from 'final-form-focus';
import React from 'react';
import {
  List,
  Datagrid,
  EditButton,
  DateField,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  BooleanInput,
  ReferenceField,
  email,
} from 'react-admin';

import { mascaraTelefoneOuCelular } from '../../helpers/masks';
import ToggleInteresse from './toggleInteresse';

const focusOnErrors = createDecorator();

const InteresseTitle = ({ record }) => (
  <span>
    Interesse de {record.name} em {record.date}
  </span>
);

const InteresseFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_INTERESSES_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

const ImovelField = ({ record, ...rest }) => (
  <span>
    {record.address}, {record.number}
  </span>
);

export const Interesse = (props) => {
  const { permissions } = props;
  return (
    <List
      title="Interesse"
      bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      filters={<InteresseFilter />}
      {...props}
    >
      <Datagrid>
        <ToggleInteresse label="Ativo" {...props} />
        <TextField label="Nome" source="name" />
        <TextField label="E-Mail" source="email" />
        <TextField label="Telefone" source="phone" />
        <ReferenceField label="Imóvel" source="property" reference="properties">
          <ImovelField />
        </ReferenceField>
        <DateField
          options={{ timeZone: 'UTC' }}
          locales="pt-BR"
          source="date"
          label="Data"
        />
        {permissions &&
          (permissions.includes('SYS_ADMIN') ||
            permissions.includes('ROLE_INTERESSES_UPDATE')) && <EditButton />}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} className="botoesUpdate">
    <SaveButton />
    {permissions &&
      (permissions.includes('SYS_ADMIN') ||
        permissions.includes('ROLE_INTERESSES_DELETE')) && <DeleteButton />}
  </Toolbar>
);

export const InteresseEdit = (props) => {
  const { permissions } = props;
  return (
    <Edit title={<InteresseTitle />} {...props}>
      <SimpleForm
        decorators={[focusOnErrors]}
        redirect="list"
        toolbar={<CustomToolbar permissions={permissions} />}
      >
        <TextInput label="Nome" validate={required()} source="name" />
        <TextInput
          label="E-mail"
          validate={[required(), email()]}
          source="email"
        />
        <TextInput
          InputProps={{
            inputComponent: mascaraTelefoneOuCelular,
          }}
          label="Telefone"
          validate={required()}
          source="phone"
        />
        <BooleanInput label="Visualizar" source="seen" />
      </SimpleForm>
    </Edit>
  );
};

export default Interesse;
