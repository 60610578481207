import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import createDecorator from 'final-form-focus';
import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  ImageInput,
  ImageField,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  email,
  FormDataConsumer,
} from 'react-admin';
import MaskedInput from 'react-text-mask';

import { mascaraCelular } from '../../helpers/masks';
import CEPAuto from '../Imoveis/CEPAuto';
import ColorPicker from './ColorPicker';

const focusOnErrors = createDecorator();

function MascaraTelefone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
}

const LojasTitle = ({ record }) => (
  <span>Imobiliária {record ? `"${record.name}"` : ''}</span>
);

const LojasFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {permissions &&
      permissions.includes('SYS_ADMIN') &&
      props.selectedIds.indexOf(parseInt(localStorage.getItem('store'), 10)) ===
        -1 && <BulkDeleteButton {...props} />}
  </>
);

export const Lojas = (props) => {
  const { permissions } = props;
  return (
    <List
      title="Imobiliárias"
      bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      filters={<LojasFilter />}
      {...props}
    >
      <Datagrid>
        <ImageField source="image" title="Imagem" label="Ícone" />
        <TextField label="Estado" source="state" />
        <TextField label="Cidade" source="city" />
        <TextField label="Nome" source="name" />
        {permissions &&
          (permissions.includes('SYS_ADMIN') ||
            permissions.includes('ROLE_LOJAS_UPDATE')) && <EditButton />}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} className="botoesUpdate">
    <SaveButton />
    {permissions &&
      permissions.includes('SYS_ADMIN') &&
      props.record.id !== parseInt(localStorage.getItem('store'), 10) && (
        <DeleteButton />
      )}
  </Toolbar>
);

const WebsiteLink = ({ permissions }) => {
  if (permissions && permissions.includes('SYS_ADMIN'))
    return (
      <TextInput
        fullWidth
        validate={required()}
        label="Website da Imobiliária"
        source="website_link"
      />
    );
  return null;
};

const Captchas = ({ permissions }) => {
  if (permissions && permissions.includes('SYS_ADMIN'))
    return (
      <>
        <TextInput
          fullWidth
          validate={required()}
          label="Chave do Site do Captcha"
          source="captcha_site_key"
        />
        <TextInput
          fullWidth
          validate={required()}
          label="Chave do Segredo do Captcha"
          source="captcha_secret_key"
        />
      </>
    );
  return null;
};

const Analytics = ({ permissions }) => {
  if (permissions && permissions.includes('SYS_ADMIN'))
    return (
      <TextInput
        fullWidth
        validate={required()}
        label="ID do Analytics"
        source="ga_tracking_id"
      />
    );
  return null;
};

const Infos = () => (
  <div>
    <TextInput
      validate={required()}
      label="Nome"
      source="name"
      style={{ marginRight: '10px' }}
    />
    <TextInput
      validate={[required(), email()]}
      label="E-mail"
      source="email"
      style={{ marginRight: '10px' }}
    />
    <TextInput
      label="WhatsApp"
      InputProps={{
        inputComponent: mascaraCelular,
      }}
      source="whatsapp"
    />
  </div>
);

const Cores = () => (
  <div style={{ width: '100%', display: 'flex' }}>
    <div style={{ width: '100%' }}>
      <ColorPicker source="color1" label="Cor Primária" />
      <ColorPicker source="color2" label="Cor Secundária" />
      <ColorPicker source="color3" label="Cor Terciária" />
    </div>
    <div style={{ marginRight: '10%', width: '100%' }}>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <div
              key={formData}
              style={{
                backgroundColor: formData.color3,
                display: 'flex',
              }}
            >
              <Typography
                style={{
                  color: formData.color1,
                  marginLeft: 50,
                  cursor: 'pointer',
                }}
                variant="h6"
              >
                Links (cor primária)
              </Typography>
            </div>
            <Button
              variant="outlined"
              style={{
                color: formData.color2,
                marginTop: '14px',
                marginLeft: '5px',
              }}
            >
              Cor Secundária
            </Button>
            <Slider
              defaultValue={0}
              style={{
                color: formData.color2,
              }}
              valueLabelDisplay="auto"
            />
          </>
        )}
      </FormDataConsumer>
    </div>
  </div>
);

export const LojasEdit = (props) => {
  const { permissions } = props;

  return (
    <Edit title={<LojasTitle />} {...props}>
      <SimpleForm
        decorators={[focusOnErrors]}
        redirect="list"
        toolbar={<CustomToolbar permissions={permissions} />}
      >
        <ImageInput
          validate={required()}
          source="pictures"
          label="Ícone"
          accept="image/*"
        >
          <ImageField source="src" title="Ícone" />
        </ImageInput>
        <ImageInput
          validate={required()}
          source="watermark"
          label="Marca D'Água"
          accept="image/*"
        >
          <ImageField source="src" title="Marca D'Água" />
        </ImageInput>
        <Infos />
        <TextInput
          validate={required()}
          label="Link do Mapa"
          source="linkMaps"
          fullWidth
        />
        <CEPAuto />
        <ArrayInput source="phones" validate={required()} label="Telefone(s)">
          <SimpleFormIterator>
            <TextInput
              key="phone"
              label="Número"
              InputProps={{
                inputComponent: MascaraTelefone,
              }}
              validate={required()}
              source="phone"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput label="Mostrar FCI?" source="fci" />
        <RichTextInput label="Texto do FCI" source="fci_message" />
        <RichTextInput
          toolbar={[['bold', 'italic', 'underline']]}
          label="Horário de atendimento"
          source="working_hours"
        />
        <Cores />
        <BooleanInput label="Desabilitar imobiliária?" source="disabled" />
        <WebsiteLink {...props} />
        <Captchas {...props} />
        <Analytics {...props} />
      </SimpleForm>
    </Edit>
  );
};

export const LojasCreate = (props) => (
  <Create {...props} title="Nova Imobiliária">
    <SimpleForm decorators={[focusOnErrors]} redirect="list">
      <ImageInput
        validate={required()}
        source="pictures"
        label="Ícone"
        accept="image/*"
      >
        <ImageField source="src" title="Ícone" />
      </ImageInput>
      <ImageInput
        validate={required()}
        source="watermark"
        label="Marca D'Água"
        accept="image/*"
      >
        <ImageField source="src" title="Marca D'Água" />
      </ImageInput>
      <Infos />
      <TextInput
        validate={required()}
        label="Link do Mapa"
        fullWidth
        source="linkMaps"
      />
      <CEPAuto />
      <ArrayInput source="phones" validate={required()} label="Telefone(s)">
        <SimpleFormIterator>
          <TextInput
            key="phone"
            label="Número"
            InputProps={{
              inputComponent: MascaraTelefone,
            }}
            validate={required()}
            source="phone"
          />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput label="Mostrar FCI?" source="fci" />
      <RichTextInput label="Texto do FCI" source="fci_message" />
      <RichTextInput
        toolbar={[['bold', 'italic', 'underline']]}
        label="Horário de atendimento"
        source="working_hours"
      />
      <Cores />
      <WebsiteLink {...props} />
      <Captchas {...props} />
      <Analytics {...props} />
    </SimpleForm>
  </Create>
);

export default Lojas;
