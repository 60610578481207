import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    common: {
      black: 'rgba(30, 30, 30, 1)',
      white: '#fff',
    },
    background: {
      paper: 'rgba(255, 255, 255, 1)',
      default: 'rgba(238, 238, 238, 1)',
    },
    primary: {
      light: 'rgba(226, 57, 46, 1)',
      main: 'rgba(178, 37, 28, 1)',
      dark: 'rgba(131, 31, 24, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgb(170,0,0)',
      main: 'rgb(144, 1, 1)',
      dark: 'rgb(109,0,0)',
      contrastText: '#FFF',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(54, 54, 54, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});

export default theme;
