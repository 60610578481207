import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { TextInput, required } from 'react-admin';
import { SketchPicker } from 'react-color';
import { useFormState, useForm } from 'react-final-form';

const ColorPicker = (props) => {
  const { label, source } = props;

  // Para pegar o form desse componente
  const form = useForm();
  const formState = useFormState();

  const dado = formState.values[`${source}`];

  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState(dado || '');

  return (
    <>
      <div>
        <TextInput
          validate={required()}
          label={label}
          source={source}
          value={color}
        />
        <Button
          onClick={() => {
            setVisible(!visible);
          }}
          variant="outlined"
          style={{ marginTop: '14px', marginLeft: '5px' }}
        >
          +
        </Button>
      </div>

      <div style={{ display: visible ? 'block' : 'none' }}>
        <SketchPicker
          color={color}
          onChange={(resultado) => {
            setColor(resultado.hex);
          }}
          onChangeComplete={(resultado) => {
            form.change(source, resultado.hex);
          }}
        />
      </div>
    </>
  );
};
export default ColorPicker;
